<template>
  <org-users></org-users>
</template>

<script>
import OrgUsers from './OrgUsers.vue';

export default {
  components: {
    OrgUsers,
  },
};
</script>
