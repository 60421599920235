<template>
  <a-table
    :columns="columns"
    :data-source="list"
    bordered
    :scroll="{ y: '55vh', x: true }"
    :loading="isFetchingUsers"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Users: &nbsp;
        <a-tag color="blue" id="users-count">{{ list.length }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.dataIndex === 'action'">
        <a-popconfirm
          :title="`Are you sure? You want to delete the user ${record.email}`"
          ok-text="Yes"
          cancel-text="No"
          @confirm="$emit('deleteUser', record.email)"
          :okButtonProps="{ id: 'delete-user-ok-btn' }"
          :cancelButtonProps="{
            id: 'delete-user-cancel-btn',
          }"
        >
          <a-button type="primary" danger :id="'delete-user-btn-' + index">
            <template #icon><i class="bi bi-trash mr-2"></i></template>
            Delete
          </a-button>
        </a-popconfirm>

        <a-button v-if="record.role == 'Workstation User'" @click="this.handleShowForm(record.email)" type="primary" class="ml-2" :id="'edit-user-btn-' + index">
            <template #icon><i class="bi bi-pencil mr-2"></i></template>
            Edit
          </a-button>

           <a-modal
              v-model:visible="showForm"
              centered
              :closable="false"
              title="Edit Workstation User"
              :footer="null"
              id="edit-worksation-user-modal"
              destroyOnClose
            >
              <edit-work-station-user-modal
                @updateWorkStationUser="updateWorkStationUserData"
                @cancel="handleShowForm"
                :isLoading="isLoading"
                :username="username"
              ></edit-work-station-user-modal>
            </a-modal>
      </template>
    </template>
  </a-table>
</template>
<script>

import EditWorkStationUserModal from './EditWorkStationUser.vue'
import AuthService from 'src/services/auth'
import { useToast } from 'vue-toastification';

export default {
  props: ['list', 'isFetchingUsers'],
  emits: ['deleteUser'],
  components : {
    EditWorkStationUserModal
  },

 setup() {
    const toast = useToast();
    return { toast };
  },
  methods:{
      handleShowForm(u_name)
      {
          this.username = u_name
          this.showForm = !this.showForm
      },
      async updateWorkStationUserData(payload)
      {
        const [error,data] = await AuthService.UpdateWorkStationUserPassword(payload)
        if(error)
        {
          this.toast.error("Password Update Failed")
          return
        }
        this.toast.success(data.response)
        this.handleShowForm()
      }
  },

  data() {
    return {
      showForm: false,
      username:"",
      columns: [
        {
          title: 'First Name',
          dataIndex: 'username',
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Role',
          dataIndex: 'role',
        },
        {
          title: 'Action',
          dataIndex: 'action',
        },
      ],
    };
  },
};
</script>
<style></style>
